/**
 * A custom React hook that tracks the state of a CSS media query.
 *
 * The hook listens for changes to the media query and updates the state accordingly.
 * It returns a boolean value indicating whether the media query matches the current viewport.
 *
 * @param query - The CSS media query string to evaluate.
 * @returns - The current state of the media query (true if it matches, false otherwise).
 */
import { useEffect, useState } from 'react';

export function useMediaQuery(query: string) {
  const [value, setValue] = useState(false);

  useEffect(() => {
    function onChange(event: MediaQueryListEvent) {
      setValue(event.matches);
    }

    const result = matchMedia(query);
    result.addEventListener('change', onChange);
    setValue(result.matches);

    return () => result.removeEventListener('change', onChange);
  }, [query]);

  return value;
}
