import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

export const subscribeSchema = z.object({
  email: z.string().email(),
});
export type SubscribeSchema = z.infer<typeof subscribeSchema>;
export const subscribeSchemaResolver = zodResolver(subscribeSchema);

export const enquireSchema = z.object({
  name: z.string().min(1),
  email: z.string().email(),
  phoneNumber: z.string(),
  message: z.string(),
});
export type EnquireSchema = z.infer<typeof enquireSchema>;
export const enquireSchemaResolver = zodResolver(enquireSchema);
