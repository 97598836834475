import { TrainerCard } from '~/components/trainer-card';
import { Typography } from '~/components/typography';

export function TeamAbhyasFeatureCards() {
  return (
    <div className="grid grid-cols-2 gap-x-4 gap-y-16 lg:grid-cols-4">
      <TrainerCard
        name="Alla"
        description="Leadership & Wellbeing"
        imageUrl="https://public-assets.abhyas.mu/cdn-cgi/image/width=960,format=auto,quality=80/images/trainers/alla.jpg"
      >
        <Typography className="text-base font-light">
          I came to the path of Yoga of the physical realm in 2004 and have been a dedicated practitioner since. Later
          on I went to India and studied Yoga and Ayurveda in Rishikesh, Kerala, Karnataka and Mumbai. As Yogic
          practices helped me on my healing journey I have been sharing with others what I have learned through the
          teachings and my own experiences. I spent a few years travelling across different continents, teaching Yoga
          and Ayurveda therapy at various Yoga retreats, luxury resorts, Yoga schools and ashrams
        </Typography>
        <Typography className="mt-4 text-base font-light">
          I came to the path of the true essence of Yog when I joined Abhyas School of Yoga, India, in February 2022.
          Since then I have been one of the lead Yoga instructors, chosen by the Master to impart the teachings of Yog
          and the message of health and well-being to the world.
        </Typography>
        <Typography className="mt-4 text-base font-light">
          Having been a part of Abhyas School of Yoga, I have been engaged into designing training materials for
          practitioners, conducting online Yoga classes, giving talks on Ayurveda and lifestyle for the retreat program
          guests, and extensively creating social media content through videos and posts to bring awareness to the world
          of the real essence of Yog.
        </Typography>
        <Typography className="mt-4 text-base font-light">
          At ASY Mauritius I have been engaged in the international conference presentations and talks on Happiness,
          Emotional Well-Being, the Relevance of Yoga and Meditation as well as designing tailored courses for corporate
          organisational leaders to help bring the practical aspect of Yoga into real lives of those who are at the peak
          of the affairs.
        </Typography>
        <Typography className="mt-4 text-base font-light">
          My greatest asset has been being under the guidance of the Masters of Yog - Sadguru Sadafal Deo ji Maharaj and
          Sant Shri Naam Deo ji Maharaj. It is their teachings that I have been imbibing. As I have been benefiting from
          these teachings I have been sharing the same with others so they could also benefit.
        </Typography>
      </TrainerCard>
      <TrainerCard
        name="Manish"
        description="Growth & Collaboration"
        imageUrl="https://public-assets.abhyas.mu/cdn-cgi/image/width=960,format=auto,quality=80/images/trainers/manish.jpg"
      >
        <Typography className="text-base font-light">
          The Abhyas School of Yoga (ASY) Mauritius chapter began in December 2020, marking a transformative period in
          my life when I was introduced to the concept of ASY and shortly thereafter met Naam Deo Ji. As a Chartered
          Certified Accountant, I had spent almost seventeen years studying and working in the United Kingdom before
          deciding to return to my homeland, Mauritius. Deep within, I always harboured a keen desire to improve the
          lives of my fellow countrymen, but lacked guidance on how to pursue this noble goal. Little did I know that
          meeting Naam Deo Ji would make my dream possible.
        </Typography>
        <Typography className="mt-4 text-base font-light">
          In July 2021, a trust was established to initiate activities in Mauritius, and by February 2023, Abhyas School
          of Yoga Ltd was created to extend its reach into the corporate sector. In October 2023, I made the significant
          decision to resign from my position as CFO of Paltoni Retail Ltd (Intermart) to fully dedicate my life to
          serving the people of Mauritius through Yog.
        </Typography>
      </TrainerCard>
      <TrainerCard
        name="Rumi"
        description="Therapy and Rehabilitation"
        imageUrl="https://public-assets.abhyas.mu/cdn-cgi/image/width=960,format=auto,quality=80/images/trainers/rumi.jpg"
      >
        <Typography className="text-base font-light">
          I completed my 3000-hour Yoga course at S-VYASA University in Bangalore, India, followed by additional
          training at Abhyas School of Yoga in Mumbai, India.
        </Typography>
        <Typography className="mt-4 text-base font-light">
          I worked as a Yoga Consultant at Narayana Health City Cardiac and Multi Hospital in Bangalore, India, where I
          was responsible for treating patients through Yoga therapy, physiotherapy, and natural healing. In this role,
          I contributed to the department of Rehabilitation and Medicine, helping individuals from various departments,
          including outpatients, inpatients, and those in pre- and post-operative care.
        </Typography>
        <Typography className="mt-4 text-base font-light">
          Working with Abhyas, I pledged my life to the cause of Naam Deo, dedicated to taking Yoga, Ayurveda, and the
          Indian heritage to the world. I was honoured to become an instructor at the Abhyas School of Yoga. Through
          this school, The Master imparts the real essence of Yoga and guides us on how we can find ourselves and do
          what is needed. I am super excited to have you on board on this transformative yogic journey, which will help
          you tap into the unimagined possibilities of your life.
        </Typography>
      </TrainerCard>
      <TrainerCard
        name="Richard"
        description="Meditation & Quality Control"
        imageUrl="https://public-assets.abhyas.mu/cdn-cgi/image/width=960,format=auto,quality=80/images/trainers/richard.jpg"
      >
        <Typography className="text-base font-light">
          Namaskar! I am Richard - a devoted practitioner of the Yogic Discipline. My life has been turned around
          completely by the &apos;sGrace of The Master&apos;s after having lived through diverse phases of Psychotic
          Clinical Depression accompanied by Bipolar Disorder & Anxiety Disorder which persisted for a period of 8 long
          years.
        </Typography>
        <Typography className="mt-4 text-base font-light">
          I am passionate soul whose ultimate purpose of life lies in serving humanity by &apos;ascending human
          consciousness to a higher dimension of life&apos;s, eradicating misery from every soul in grief & despair, and
          eliminating all forms of racism on the planet. A firm believer of the mission of global peace and harmony of
          Naam Deo!
        </Typography>
      </TrainerCard>
    </div>
  );
}
