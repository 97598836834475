import { Button } from '@asy/shadcn-ui/components/button';
import { Separator } from '@asy/shadcn-ui/components/separator';
import { cn } from '@asy/shadcn-ui/lib/utils';
import { Link, useLocation } from '@remix-run/react';
import { FacebookIcon, MailIcon, PhoneCallIcon, YoutubeIcon } from 'lucide-react';
import { Typography } from '~/components/typography';

interface FooterProps {
  className?: string;
}

export function Footer({ className }: FooterProps) {
  const currentYear = new Date().getFullYear();
  const location = useLocation();
  let brochureLinkUrl = '';
  if (location.pathname.startsWith('/course/awareness')) {
    brochureLinkUrl = 'https://public-assets.abhyas.mu/pdfs/awareness-brochure.pdf';
  } else if (location.pathname.startsWith('/course/emotional-well-being')) {
    brochureLinkUrl = 'https://public-assets.abhyas.mu/pdfs/emotional-wellbeing-brochure.pdf';
  } else if (location.pathname.startsWith('/course/conscious-leadership')) {
    brochureLinkUrl = 'https://public-assets.abhyas.mu/pdfs/conscious-leadership-brochure.pdf';
  } else {
    brochureLinkUrl = 'https://public-assets.abhyas.mu/pdfs/abhyas-brochure.pdf';
  }

  return (
    <footer className={cn('bg-accent px-5 py-10 md:px-10 lg:px-36', className)}>
      <div className="flex flex-col items-start justify-between gap-10 md:flex-row md:gap-0">
        <div className="flex flex-col space-y-4">
          <img
            src="https://public-assets.abhyas.mu/cdn-cgi/image/height=120px,format=auto,quality=80/images/iconography/logo-color.png"
            alt="Abhyas"
            className="h-auto w-auto max-w-[200px]"
          />
          <Typography>
            Riviere Baptiste Road <br />
            La Laura, Saint Pierre <br />
            Mauritius, 80502
          </Typography>
          <Typography>
            <a href="tel:+23052745555">
              <PhoneCallIcon className="inline" />
              +230 5274 5555
            </a>
          </Typography>
          <Typography>
            <a href="mailto:info@abhyas.mu">
              <MailIcon className="inline" /> Email Us
            </a>
          </Typography>
        </div>
        <div className="space-y-4">
          <Typography variant="h4">Quick Links</Typography>
          <ul className="space-y-2">
            <li>
              <Link to="/" prefetch="intent">
                Home
              </Link>
            </li>
            <li>
              <Link to="/about" prefetch="intent">
                About
              </Link>
            </li>
            <li>
              <Link to="/#our-courses" preventScrollReset prefetch="intent">
                Courses
              </Link>
            </li>
            <li>
              <Link to="/course/train-the-trainer" prefetch="intent">
                Training
              </Link>
            </li>
            <li>
              <Link to="/retreat" prefetch="intent">
                Retreats
              </Link>
            </li>
          </ul>
        </div>
        <div className="flex flex-col space-y-4">
          <Typography variant="h4">Important Information</Typography>
          <ul className="space-y-2">
            <li>
              <Link to="/terms-and-conditions" prefetch="intent">
                Terms and Conditions
              </Link>
            </li>
            <li>
              <Link to="/privacy-policy" prefetch="intent">
                Privacy Policy
              </Link>
            </li>
            <li>
              <a href={brochureLinkUrl} target="_blank" rel="noreferrer">
                <Button className="my-10-2 font-mono uppercase">Download Brochure</Button>
              </a>
            </li>
            <li className="flex flex-row">
              <a
                href="https://fb.com/abhyasltd"
                rel="noopener noreferrer"
                target="_blank"
                className="mr-2 rounded-full bg-primary p-2 lg:mr-6"
              >
                <FacebookIcon color="white" />
              </a>
              <a
                href="https://www.youtube.com/@abhyasmauritius"
                rel="noopener noreferrer"
                target="_blank"
                className="mr-2 rounded-full bg-primary p-2 lg:mr-6"
              >
                <YoutubeIcon color="white" />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <Separator className="mb-5 mt-10 bg-primary" />
      <div className="text-center">
        <Typography variant="smallText">© {currentYear} Abhyas. All rights reserved</Typography>
      </div>
    </footer>
  );
}
