import { cn } from '@asy/shadcn-ui/lib/utils';

export function HeroSection({ children, className }: { children: React.ReactNode; className?: string }) {
  return (
    <section
      className={cn(
        'relative h-[calc(50vh-60px)] sm:h-[calc(50vh-70px)] md:h-[calc(50vh-70px)] lg:h-[calc(80vh-70px)]',
        className
      )}
    >
      {children}
    </section>
  );
}
