import { generateSrcSet } from '@asy/react-lib/url';
import { Button } from '@asy/shadcn-ui/components/button';
import { HeroVideoDialog } from '@asy/shadcn-ui/magicui/hero-video-dialog';
import { MetaFunction } from '@remix-run/cloudflare';
import { Link } from '@remix-run/react';
import { useMemo } from 'react';
import { ConnectWithAbhyasSection } from '~/components/connect-section';
import { EnquireDialog } from '~/components/enquire-dialog';
import { FeatureCard } from '~/components/feature-card';
import { HeroCarousel } from '~/components/hero-carousel';
import { ContentSection } from '~/components/page-layout/content-section';
import { SectionImageCard } from '~/components/page-layout/section-card';
import { TeamAbhyasFeatureCards } from '~/components/team-abhyas-feature-cards';
import { TextQuoteSection } from '~/components/text-quote-section';
import { Typography } from '~/components/typography';
import { getSeoMeta } from '~/lib/seo';

export const meta: MetaFunction = (args) => {
  return getSeoMeta({ title: 'Home', description: '' }, args);
};

export default function NewHomePage() {
  const communityImageSrcSet = useMemo(
    () =>
      generateSrcSet(
        'https://public-assets.abhyas.mu/cdn-cgi/image/width=1920,format=auto,quality=80/images/homepage/get-involved.jpg',
        [640, 960, 1280, 1920]
      ),
    []
  );
  return (
    <>
      <HeroCarousel
        delayMs={5000}
        slides={[
          {
            image:
              'https://public-assets.abhyas.mu/cdn-cgi/image/width=1920,format=auto,quality=80/images/about/cover.jpg',
            title: 'Institute of Universal Awareness',
            description:
              'TTT program has been developed for well-being professionals and enthusiasts, empowering them with the practical, experience-based approach of the Abhyas.',
            ctaLink: '/institute-of-universal-awareness',
            ctaText: 'Explore',
          },
          {
            image:
              'https://public-assets.abhyas.mu/cdn-cgi/image/width=1920,format=auto,quality=80/images/homepage/hero-corporate-banner.jpg',
            title: 'Adapt Proven Practices',
            description:
              'An inability to handle stress, especially at the workplace, is one of the main causes of mental and physical health issues. When the stress is processed in the right way, there is progress',
            ctaLink: '/workplace',
            ctaText: 'Explore',
          },
          {
            image:
              'https://public-assets.abhyas.mu/cdn-cgi/image/width=1920,format=auto,quality=80/images/homepage/hero-university-banner.jpg',
            title: 'Need of a well formed mind',
            description:
              'We are entering a new renaissance where a well-formed mind could be totally devoid of all the information but can reach the peak of creativity.',
            ctaLink: '/university-programs',
            ctaText: 'Explore',
          },
        ]}
      />
      <ContentSection>
        <Typography variant="h2" as="h1" className="mb-4 mt-6 text-balance text-center font-serif font-light lg:mt-0">
          All transformations have one thing in common: the turnaround in the &apos;perspective&apos; on what they have
          been through.
        </Typography>
        <HeroVideoDialog
          className="mx-auto md:max-w-3xl lg:max-w-4xl"
          videoSrc="https://public-assets.abhyas.mu/videos/homepage-testimonial-montage.mp4"
          thumbnailSrc="https://public-assets.abhyas.mu/cdn-cgi/image/width=1920,format=auto,quality=80/images/homepage/testimonial-montage-preview.jpg"
          borderAnimation="border-beam"
        />
      </ContentSection>
      <ContentSection className="bg-accent text-accent-foreground">
        <SectionImageCard
          imageUrl="https://public-assets.abhyas.mu/cdn-cgi/image/width=960,format=auto,quality=80/images/homepage/adsp-collab.jpg"
          imageAlt="Collaboration with ADSP"
          imagePosition="left"
          imageClassName="lg:w-[45%]"
          textContainerClassName="lg:w-[50%]"
        >
          <Typography variant="h2" as="h1" className="mb-4 mt-6 font-serif font-light lg:mt-0">
            Collaboration with ADSP School in the development of Special Needs Children
          </Typography>
          <Typography className="mb-4">
            Since March 2023, Abhyas has been working jointly with the Association of Disability Service Providers
            (ADSP) on a continuous workshop titled “Help Ourselves to Help Others” to support the development of
            children with special needs.
          </Typography>
          <Typography className="mb-4">
            The school recognizes the importance of mental well-being sessions in supporting the inner growth of these
            children. These practices benefit not only the students but also the staff, providing them with the
            discipline and insights needed to effectively address the needs of special students.
          </Typography>
          {/* <Button size="lg" asChild>
            <Link to="/workshops?workshop-slug=future-ready-with-presence">Explore all recent workshops</Link>
          </Button> */}
        </SectionImageCard>
      </ContentSection>
      <ContentSection>
        <SectionImageCard
          imageUrl="https://public-assets.abhyas.mu/cdn-cgi/image/width=960,format=auto,quality=80/images/homepage/positive-shift.jpg"
          imageAlt="Positive shift in both our individual well-being and team dynamics."
          imagePosition="right"
          imageClassName="lg:w-[45%]"
          textContainerClassName="lg:w-[50%]"
        >
          <Typography variant="h2" as="h1" className="mb-4 mt-6 font-serif font-light lg:mt-0">
            Positive shift in both our individual well-being and team dynamics.
          </Typography>
          <Typography className="mb-4">
            At Ekium Amio, we have been practising sessions twice a month with guidance from Abhyas, and the experience
            has been truly transformative. The guidance has not only helped our team become more flexible but also
            brought a sense of calm and mental clarity to our work environment. The sessions are perfectly tailored to
            our needs and provide the perfect break from the hustle of daily tasks.
          </Typography>
          <Typography className="mb-4">
            <strong>
              Since incorporating these practices into our routine, we&apos;ve noticed a positive shift in both our
              individual well-being and team dynamics.
            </strong>
          </Typography>
          <Typography className="text-right">
            <strong>Ekium Amio Ltd</strong>
          </Typography>
          <Button size="lg" asChild>
            <Link to="/mqa-courses">Explore all MQA approved programs</Link>
          </Button>
        </SectionImageCard>
      </ContentSection>
      <ContentSection className="bg-accent text-accent-foreground">
        <TextQuoteSection
          quoteText="The guidance and technique have led to a disciplined, less reactive lifestyle, enhancing my inner peace and work relationships."
          author="Neermal"
          role="Senior Vice President, MCB Ltd"
          className="bg-background text-foreground"
        />
      </ContentSection>
      <ContentSection>
        <Typography variant="h1" className="pb-10 text-center font-serif font-light lg:pb-14">
          Select a reason to begin with
        </Typography>
        <div className="grid grid-cols-1 gap-x-10 gap-y-16 md:grid-cols-3">
          <FeatureCard
            title="For my Organisation"
            description=""
            imageUrl="https://public-assets.abhyas.mu/cdn-cgi/image/width=960,format=auto,quality=80/images/homepage/reason-for-org.jpg"
            imageAlt="For my Organisation"
            cardLink="/workplace"
          />
          <FeatureCard
            title="For Myself"
            description=""
            imageUrl="https://public-assets.abhyas.mu/cdn-cgi/image/width=960,format=auto,quality=80/images/homepage/reason-for-self.jpg"
            imageAlt="For Myself"
          />
          <FeatureCard
            title="Someone I Know"
            description=""
            imageUrl="https://public-assets.abhyas.mu/cdn-cgi/image/width=960,format=auto,quality=80/images/homepage/reason-someone-i-know.jpg"
            imageAlt="Someone I know"
          />
        </div>
      </ContentSection>
      <ContentSection className="bg-accent text-accent-foreground">
        <SectionImageCard
          imageUrl="https://public-assets.abhyas.mu/cdn-cgi/image/width=960,format=auto,quality=80/images/homepage/dr-udit.jpg"
          imageAlt="Positive shift in both our individual well-being and team dynamics."
          imagePosition="left"
          imageClassName="lg:w-[45%]"
          textContainerClassName="lg:w-[50%]"
        >
          <Typography variant="h2" as="h1" className="mb-4 mt-6 font-serif font-light lg:mt-0">
            &quot;Abhyas has given me a new perspective to look at the challenges as an opportunity of growth!&quot;
          </Typography>
          <Typography className="mb-4 text-right">
            <strong>Dr Udit</strong>
          </Typography>
          <Typography className="mb-4">
            Meeting people at Abhyas made me realise that there are still good hearted people, who are willing to help
            others selflessly and without discrimination. Knowing that they left everything and came to a different
            country and to help people selflessly inspired me, gave me hope, strength, and an opportunity to join their
            noble cause.
          </Typography>
          <Typography className="mb-4">
            Through the teachings, I realised that all our problems and difficulties that we face in life are means to
            strengthen ourselves and look at life with a new perspective. Not to lament and lose hope on our problems
            but to see it as a challenge. It has been a blessing to be part of Abhyas Family!
          </Typography>
          <Button size="lg" asChild>
            <a
              href="https://www.youtube.com/playlist?list=PL3s664I5s757RQUDbr59XanGVe_SZzem0"
              target="_blank"
              rel="noreferrer"
            >
              Explore all the stories to get inspired from
            </a>
          </Button>
        </SectionImageCard>
      </ContentSection>
      <ContentSection className="flex flex-col items-center justify-center gap-8 md:flex-row md:gap-24">
        <div>
          <Typography variant="h2" as="h1" className="mb-4 mt-6 font-serif font-light lg:mt-0">
            &quot;Why traditional approach to cope with the issue is not a solution in a long-term&quot;
          </Typography>
          <Typography>
            As such, the need for comprehensive transformation in mental health care is undeniable and urgent. Every
            country, regardless of resource constraints, has an opportunity to take substantial steps to support change
            in mental health care access, as there is a growing need for trained professionals and expanded resources.
          </Typography>
        </div>
        <Button size="lg" asChild>
          <Link to="/workshops?workshop-slug=mental-health-in-the-digital-age">Read all blogs</Link>
        </Button>
      </ContentSection>
      <ContentSection className="bg-accent text-accent-foreground">
        <Typography variant="h1" className="pb-10 text-center font-serif font-light lg:pb-14">
          Volunteer at Abhyas
        </Typography>
        <div className="grid grid-cols-1 gap-x-10 gap-y-16 md:grid-cols-3">
          <FeatureCard
            title="Skill Based"
            description=""
            imageUrl="https://public-assets.abhyas.mu/cdn-cgi/image/width=960,format=auto,quality=80/images/homepage/volunteer-skill-based.jpg"
            imageAlt="Skill Based"
          />
          <FeatureCard
            title="Events"
            description=""
            imageUrl="https://public-assets.abhyas.mu/cdn-cgi/image/width=960,format=auto,quality=80/images/homepage/volunteer-events.jpg"
            imageAlt="Events"
          />
          <FeatureCard
            title="Other Ways"
            description=""
            imageUrl="https://public-assets.abhyas.mu/cdn-cgi/image/width=960,format=auto,quality=80/images/homepage/volunteer-other-ways.jpg"
            imageAlt="Other Ways"
          />
        </div>
      </ContentSection>
      <ContentSection>
        <div className="flex flex-col items-center justify-center gap-8 md:flex-row md:gap-24">
          <div>
            <Typography variant="h2" as="h1" className="mb-4 mt-6 font-serif font-light lg:mt-0">
              Corporate Train the Trainer Program
            </Typography>
            <Typography>
              The Train the Trainer (TTT) program has been developed for well-being professionals and enthusiasts,
              empowering them with the practical, experience-based approach of the Abhyas Institute of Universal
              Awareness. The program emphasises uncovering the root causes of complex issues through experiential
              science and the application of life-guiding principles, beginning with self-implementation of the
              program&apos;s structure.
            </Typography>
          </div>
          <Button size="lg" asChild>
            <Link to="/course/train-the-trainer">Explore</Link>
          </Button>
        </div>
      </ContentSection>
      <ContentSection className="bg-accent text-accent-foreground">
        <SectionImageCard
          imageUrl="https://public-assets.abhyas.mu/cdn-cgi/image/width=1920,format=auto,quality=80/images/homepage/testimonial-montage-preview.png"
          imageAlt="Kanushka Corporate TTT Student"
          imagePosition="right"
          imageClassName="lg:w-[45%]"
          textContainerClassName="lg:w-[50%]"
        >
          <Typography className="mb-4">
            The same situations I used to experience before, I now handle with more poise. They no longer seem like
            challenges to me. Things that once felt challenging have become easier, and I feel more comfortable in those
            situations. I often have much more clarity when making decisions as well, and it&apos;s all thanks to the
            practices I&apos;ve been doing since I started coming to Abhyas.
          </Typography>
          <Typography>
            I&apos;ve truly seen the impact in my daily life. Situations that used to trigger me now allow me to be more
            present. I can observe my emotions without reacting impulsively, which has greatly improved my relationships
            with my family and colleagues. Instead of responding based on past perspectives or patterns, I&apos;m more
            present and conscious in the moment.
          </Typography>
          <Typography className="mt-4">
            <strong>Kanusha, TTT student</strong>
          </Typography>
        </SectionImageCard>
      </ContentSection>
      <ContentSection>
        <Typography variant="h1" className="pb-10 text-center font-serif font-light lg:pb-14">
          Team Abhyas
        </Typography>
        <TeamAbhyasFeatureCards />
      </ContentSection>
      <section className="relative bg-accent text-accent-foreground">
        <img
          src="https://public-assets.abhyas.mu/cdn-cgi/image/width=1920,format=auto,quality=80/images/homepage/get-involved.jpg"
          srcSet={communityImageSrcSet}
          alt="Get involved"
          className="aspect-video w-full object-cover"
        />
        <div className="z-10 max-w-xl rounded p-10 lg:absolute lg:left-[40%] lg:right-0 lg:top-1/4 lg:bg-slate-50/75 lg:p-16">
          <Typography variant="h1" as="h2" className="font-serif font-light">
            Community
          </Typography>
          <Typography className="mt-10">
            Expand the level of your awareness with an ever-growing community of professionals, practitioners, and
            certified instructors from diverse backgrounds and all walks of life to make progress every day.
          </Typography>
          <EnquireDialog triggerButton={<Button className="mt-10 uppercase">Get Involved</Button>} />
        </div>
      </section>
      <ContentSection>
        <ConnectWithAbhyasSection />
      </ContentSection>
    </>
  );
}
