import { If } from '@asy/react-lib/control-flow/If';
import { generateSrcSet } from '@asy/react-lib/url';
import { Separator } from '@asy/shadcn-ui/components/separator';
import { cn } from '@asy/shadcn-ui/lib/utils';
import { useMemo } from 'react';
import { ImageCarousel } from '~/components/image-carousel';

interface SectionCardProps {
  withBorder?: boolean;
  imageUrl: string;
  imageAlt: string;
  swiperImages?: {
    src: string;
  }[];
  imagePosition: 'left' | 'right';
  imageClassName?: string;
  textContainerClassName?: string;
  children: React.ReactNode;
  className?: string;
}

export function SectionImageCard({
  withBorder,
  imageUrl,
  imageAlt,
  imagePosition,
  imageClassName,
  textContainerClassName,
  swiperImages,
  className,
  children,
}: SectionCardProps) {
  if (withBorder) {
    return (
      <Border className={className}>
        <CardContent
          imageUrl={imageUrl}
          imageAlt={imageAlt}
          imagePosition={imagePosition}
          imageClassName={imageClassName}
          textContainerClassName={textContainerClassName}
          swiperImages={swiperImages}
        >
          {children}
        </CardContent>
      </Border>
    );
  }
  return (
    <CardContent
      className={className}
      imageUrl={imageUrl}
      imageAlt={imageAlt}
      imagePosition={imagePosition}
      imageClassName={imageClassName}
      textContainerClassName={textContainerClassName}
      swiperImages={swiperImages}
    >
      {children}
    </CardContent>
  );
}

function Border({ className, children }: { className: React.ReactNode; children: React.ReactNode }) {
  return <div className={cn('rounded bg-background p-8', className)}>{children}</div>;
}

function CardContent({
  imageUrl,
  imageAlt,
  imagePosition,
  className,
  imageClassName,
  textContainerClassName,
  swiperImages,
  children,
}: SectionCardProps) {
  const imageSrcSet = useMemo(() => generateSrcSet(imageUrl, [320, 640, 960]), [imageUrl]);

  return (
    <div className={cn('flex flex-wrap rounded', { 'flex-row-reverse': imagePosition === 'right' }, className)}>
      <If condition={!swiperImages}>
        <img
          src={imageUrl}
          srcSet={imageSrcSet}
          alt={imageAlt}
          className={cn('aspect-[3/2] w-full rounded object-cover lg:w-[55%]', imageClassName)}
        />
      </If>
      <If condition={Boolean(swiperImages)}>
        <div className={cn('aspect-[3/2] w-full rounded object-cover lg:w-[55%]', imageClassName)}>
          <ImageCarousel imageUrls={swiperImages?.map((v) => v.src) ?? []} />
        </div>
      </If>
      <div className="hidden flex-grow lg:block">{/* This div provides spacing */}</div>
      <div className={cn('flex w-full flex-col justify-center lg:w-[35%]', textContainerClassName)}>
        <div>{children}</div>
      </div>
    </div>
  );
}

export function SectionTextCard({
  leftChildren,
  rightChildren,
  className,
  separator: separator = true,
}: {
  leftChildren: React.ReactNode;
  rightChildren: React.ReactNode;
  className?: string;
  separator?: boolean;
}) {
  return (
    <div className={cn('flex flex-wrap rounded', className)}>
      <div className="w-full lg:w-[49%] lg:p-10">{leftChildren}</div>
      <div className="my-6 flex-shrink lg:my-0">
        {separator && <Separator orientation="vertical" className="bg-primary" />}
      </div>
      <div className="flex w-full flex-col justify-center lg:w-[49%] lg:p-10">
        <div>{rightChildren}</div>
      </div>
    </div>
  );
}
