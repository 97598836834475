import { generateSrcSet } from '@asy/react-lib/url';
import { cn } from '@asy/shadcn-ui/lib/utils';
import { useMemo } from 'react';
import 'swiper/css';
import 'swiper/css/a11y';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, Navigation, Pagination, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';
import './swiper.css';

interface CarouselProps {
  imageUrls: string[];
  className?: string;
  sliderClassName?: string;
  onSlideChange?: (swiper: SwiperClass) => void;
}

const CarouselSlide = SwiperSlide;

function Carousel({ imageUrls, className, sliderClassName, onSlideChange }: CarouselProps) {
  const srcSets = useMemo(() => imageUrls.map((url) => generateSrcSet(url, [320, 640, 960, 1280, 1920])), [imageUrls]);

  return (
    <Swiper
      className={cn('h-full w-full', className)}
      modules={[Autoplay, Navigation, Pagination, A11y]}
      speed={2000}
      onSlideChange={onSlideChange}
      autoplay={{
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      }}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
    >
      {imageUrls.map((url, index) => (
        <SwiperSlide key={index} className={cn('w-full', sliderClassName)}>
          <img
            className="h-full w-full rounded object-cover"
            srcSet={srcSets[index]}
            src={url}
            alt={`Carousel ${index + 1}`}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export { Carousel, CarouselSlide, type CarouselProps };
