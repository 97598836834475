import { Button } from '@asy/shadcn-ui/components/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@asy/shadcn-ui/components/dialog';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@asy/shadcn-ui/components/form';
import { Input } from '@asy/shadcn-ui/components/input';
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@asy/shadcn-ui/components/sheet';
import { Textarea } from '@asy/shadcn-ui/components/textarea';
import { useMediaQuery } from '@asy/shadcn-ui/hooks/use-media-query';
import { cn } from '@asy/shadcn-ui/lib/utils';
import { useFetcher } from '@remix-run/react';
import { Loader2Icon } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import { useRemixForm, useRemixFormContext, RemixFormProvider as FormProvider } from 'remix-hook-form';
import { toast } from 'sonner';
import { EnquireSchema, enquireSchemaResolver } from '~/lib/validation-schemas';

export function EnquireDialog({ triggerButton }: { triggerButton: React.ReactNode }) {
  const isDesktop = useMediaQuery('(min-width: 768px)');
  const [formOpen, setFormOpen] = useState(false);
  const fetcher = useFetcher({ key: 'enquire-form' });
  const form = useRemixForm<EnquireSchema>({
    mode: 'onSubmit',
    resolver: enquireSchemaResolver,
    defaultValues: {
      name: '',
      email: '',
      phoneNumber: '',
      message: '',
    },
    fetcher,
    submitConfig: {
      method: 'POST',
      action: '/api/enquire',
    },
  });
  const submissionStarted = useRef(false);
  const submissionFinished = useRef(false);
  const isLoading = ['loading', 'submitting'].includes(fetcher.state);
  const isSubmitted = form.formState.isSubmitSuccessful;
  const resetForm = form.reset;

  useEffect(() => {
    if (isSubmitted && isLoading) {
      submissionStarted.current = true;
    }
    if (submissionStarted.current && !isLoading && isSubmitted) {
      submissionFinished.current = true;
    }
  }, [isLoading, isSubmitted, submissionStarted]);

  useEffect(() => {
    if (submissionFinished.current) {
      toast.success('Your enquiry has been submitted successfully.');
      setFormOpen(false);
      resetForm();
      submissionFinished.current = false;
      submissionStarted.current = false;
    }
  }, [submissionFinished, resetForm]);

  if (isDesktop) {
    return (
      <Dialog open={formOpen} onOpenChange={setFormOpen}>
        <DialogTrigger asChild>{triggerButton}</DialogTrigger>
        <DialogContent>
          <FormProvider {...form}>
            <fetcher.Form onSubmit={form.handleSubmit} className="grid gap-4">
              <DialogHeader>
                <DialogTitle>Get in touch!</DialogTitle>
                <DialogDescription>Fill out the form below to send us your enquiry.</DialogDescription>
              </DialogHeader>
              <EnquiryFormContent />
              <DialogFooter className="gap-4 sm:gap-0">
                <DialogClose asChild>
                  <Button variant="outline" disabled={isLoading}>
                    Cancel
                  </Button>
                </DialogClose>
                <Button type="submit" disabled={isLoading}>
                  <Loader2Icon className={cn('mr-1 animate-spin', { hidden: !isLoading })} />
                  Submit
                </Button>
              </DialogFooter>
            </fetcher.Form>
          </FormProvider>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Sheet open={formOpen} onOpenChange={setFormOpen}>
      <SheetTrigger asChild>{triggerButton}</SheetTrigger>
      <SheetContent side="bottom" className="h-full">
        <FormProvider {...form}>
          <fetcher.Form onSubmit={form.handleSubmit}>
            <SheetHeader className="text-left">
              <SheetTitle>Enquire with us</SheetTitle>
              <SheetDescription>Fill out the form below to send us your enquiry.</SheetDescription>
            </SheetHeader>
            <EnquiryFormContent className="p-4" />
            <SheetFooter className="gap-2">
              <Button type="submit" disabled={isLoading}>
                <Loader2Icon className={cn('mr-1 animate-spin', { hidden: !isLoading })} />
                Submit
              </Button>
              <SheetClose asChild>
                <Button variant="outline" disabled={isLoading}>
                  Cancel
                </Button>
              </SheetClose>
            </SheetFooter>
          </fetcher.Form>
        </FormProvider>
      </SheetContent>
    </Sheet>
  );
}

function EnquiryFormContent({ className }: { className?: string }) {
  const formContext = useRemixFormContext();
  return (
    <div className={cn('grid gap-2 text-left', className)}>
      <FormField
        control={formContext.control}
        name="name"
        render={({ field }) => (
          <FormItem className="space-y-0">
            <FormLabel>Name</FormLabel>
            <FormControl>
              <Input type="text" placeholder="Your Name" {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={formContext.control}
        name="email"
        render={({ field }) => (
          <FormItem className="space-y-0">
            <FormLabel>Email</FormLabel>
            <FormControl>
              <Input type="text" placeholder="Email" {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={formContext.control}
        name="phoneNumber"
        render={({ field }) => (
          <FormItem className="space-y-0">
            <FormLabel>Phone Number</FormLabel>
            <FormControl>
              <Input type="text" placeholder="Phone Number" {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={formContext.control}
        name="message"
        render={({ field }) => (
          <FormItem className="space-y-0">
            <FormLabel>Message</FormLabel>
            <FormControl>
              <Textarea placeholder="Type your message here. " {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  );
}
