import { Button } from '@asy/shadcn-ui/components/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  DropdownMenuLabel,
  DropdownMenuGroup,
} from '@asy/shadcn-ui/components/dropdown-menu';
import { cn } from '@asy/shadcn-ui/lib/utils';
import { Link } from '@remix-run/react';
import { ChevronDownIcon, MenuIcon } from 'lucide-react';

interface HeaderProps {
  className?: string;
}

export function Header({ className }: HeaderProps) {
  return (
    <header className={cn('fixed left-0 right-0 top-0 z-20 bg-white shadow-md dark:bg-gray-800', className)}>
      <nav className="container flex h-full items-center justify-between px-4">
        <div>
          <Link className="text-2xl font-bold text-gray-800 dark:text-white" to="/" prefetch="intent">
            <img
              src="https://public-assets.abhyas.mu/cdn-cgi/image/height=120px,format=auto,quality=80/images/iconography/logo-color.png"
              alt="Abhyas"
              className="h-12 w-auto"
            />
          </Link>
        </div>
        <NavLinks />
        <MobileNavLinks />
      </nav>
    </header>
  );
}

function NavLinks() {
  return (
    <nav className="hidden space-x-4 lg:block">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="link" className="font-serif text-lg font-light [&[data-state=open]>svg]:rotate-180">
            Workplace
            <ChevronDownIcon className="h-4 w-4 shrink-0 transition-transform duration-200" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <Link to="/workplace" prefetch="intent" className="font-serif font-light">
            <DropdownMenuItem className="cursor-pointer text-lg">About</DropdownMenuItem>
          </Link>
          <DropdownMenuSeparator />
          <Link to="/mqa-courses" prefetch="intent" className="font-serif font-light">
            <DropdownMenuItem className="cursor-pointer text-lg">Courses</DropdownMenuItem>
          </Link>
        </DropdownMenuContent>
      </DropdownMenu>
      <Button asChild variant="link" className="font-serif text-lg font-light">
        <Link to="/university-programs" prefetch="intent">
          Students
        </Link>
      </Button>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="link" className="font-serif text-lg font-light [&[data-state=open]>svg]:rotate-180">
            Training
            <ChevronDownIcon className="h-4 w-4 shrink-0 transition-transform duration-200" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <Link to="/institute-of-universal-awareness" prefetch="intent" className="font-serif font-light">
            <DropdownMenuItem className="cursor-pointer text-lg">About</DropdownMenuItem>
          </Link>
          <DropdownMenuSeparator />
          <Link to="/teacher-training-program" prefetch="intent" className="font-serif font-light">
            <DropdownMenuItem className="cursor-pointer text-lg">Teacher Training Program</DropdownMenuItem>
          </Link>
          <DropdownMenuSeparator />
          <Link to="/corporate-teacher-training-program" prefetch="intent" className="font-serif font-light">
            <DropdownMenuItem className="cursor-pointer text-lg">Corporate Train the Trainer</DropdownMenuItem>
          </Link>
        </DropdownMenuContent>
      </DropdownMenu>
      <Button asChild variant="link" className="font-serif text-lg font-light">
        <Link to="/retreat" prefetch="intent">
          Retreats
        </Link>
      </Button>
      <Button asChild variant="link" className="font-serif text-lg font-light">
        <Link to="/activities" prefetch="intent">
          Activities
        </Link>
      </Button>
      <Button asChild variant="link" className="font-serif text-lg font-light">
        <Link to="/about" prefetch="intent">
          About
        </Link>
      </Button>
    </nav>
  );
}

function MobileNavLinks() {
  return (
    <nav className="lg:hidden">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="px-0">
            <MenuIcon />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <Link to="/" prefetch="intent" className="font-serif font-light">
            <DropdownMenuItem className="cursor-pointer text-base">Home</DropdownMenuItem>
          </Link>
          <DropdownMenuSeparator />
          <DropdownMenuGroup>
            <DropdownMenuLabel className="font-serif text-base">Workplace</DropdownMenuLabel>
            <Link to="/workplace" prefetch="intent" className="font-serif font-light">
              <DropdownMenuItem className="cursor-pointer text-base">About</DropdownMenuItem>
            </Link>
            <Link to="/mqa-courses" prefetch="intent" className="font-serif font-light">
              <DropdownMenuItem className="cursor-pointer text-base">Courses</DropdownMenuItem>
            </Link>
          </DropdownMenuGroup>
          <DropdownMenuSeparator />
          <Link to="/university-programs" prefetch="intent" className="font-serif font-light">
            <DropdownMenuItem className="cursor-pointer text-base">Students</DropdownMenuItem>
          </Link>
          <DropdownMenuSeparator />
          <DropdownMenuGroup>
            <DropdownMenuLabel className="font-serif text-base">Training</DropdownMenuLabel>
            <Link to="/institute-of-universal-awareness" prefetch="intent" className="font-serif font-light">
              <DropdownMenuItem className="cursor-pointer text-base">About</DropdownMenuItem>
            </Link>
            <Link to="/teacher-training-program" prefetch="intent" className="font-serif font-light">
              <DropdownMenuItem className="cursor-pointer text-base">Teacher Training Program</DropdownMenuItem>
            </Link>
            <Link to="/corporate-teacher-training-program" prefetch="intent" className="font-serif font-light">
              <DropdownMenuItem className="cursor-pointer text-base">Corporate Train the Trainer</DropdownMenuItem>
            </Link>
          </DropdownMenuGroup>
          <DropdownMenuSeparator />
          <Link to="/retreat" prefetch="intent" className="font-serif font-light">
            <DropdownMenuItem className="cursor-pointer text-base">Retreat</DropdownMenuItem>
          </Link>
          <Link to="/activities" prefetch="intent" className="font-serif font-light">
            <DropdownMenuItem className="cursor-pointer text-base">Activities</DropdownMenuItem>
          </Link>
          <Link to="/about" prefetch="intent" className="font-serif font-light">
            <DropdownMenuItem className="cursor-pointer text-base">About</DropdownMenuItem>
          </Link>
        </DropdownMenuContent>
      </DropdownMenu>
    </nav>
  );
}
