import { If } from '@asy/react-lib/control-flow/If';
import { cn } from '@asy/shadcn-ui/lib/utils';
import { Quote } from 'lucide-react';
import { Typography } from './typography';

interface QuoteComponentProps {
  quoteText: string;
  author?: string;
  role?: string;
  className?: string;
}

export function TextQuoteSection({ quoteText, author, role, className }: QuoteComponentProps) {
  const quoteLines = quoteText.split('\\n').filter((line) => line.trim() !== '');

  return (
    <figure className={cn('w-full rounded-lg p-6 shadow-md sm:p-8 md:p-10', className)}>
      <Quote className={cn('mb-4 h-8 w-8 rotate-180 transform')} aria-hidden="true" />
      <Typography variant="p" as="blockquote" className="space-y-4">
        {quoteLines.map((line, index) => (
          <p key={index} className="text-base font-medium leading-relaxed sm:text-lg md:text-xl">
            {line}
          </p>
        ))}
      </Typography>
      <If condition={author ?? role}>
        <figcaption className="mt-6">
          <cite className="flex flex-col items-start not-italic">
            <If condition={author}>
              <span className="font-semibold">{author}</span>
            </If>
            <If condition={role}>
              <span className={cn('text-xs')}>{role}</span>
            </If>
          </cite>
        </figcaption>
      </If>
    </figure>
  );
}
