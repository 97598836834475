import { MetaArgs, MetaDescriptor } from '@remix-run/cloudflare';

export interface SeoInput {
  title: string;
  description?: string;
  keywords?: string;
  previewImageSrc?: string;
  previewImageAlt?: string;
}

export function getSeoMeta(
  { title, description, keywords, previewImageSrc, previewImageAlt }: SeoInput,
  args: MetaArgs,
  extraMeta?: MetaDescriptor[]
): MetaDescriptor[] {
  if (!previewImageSrc) {
    previewImageSrc =
      'https://public-assets.abhyas.mu/cdn-cgi/image/height=120px,format=auto,quality=80/images/iconography/logo-color.png';
  }
  if (!previewImageAlt) {
    previewImageAlt = 'Abhyas';
  }
  if (!description) {
    description = "The highest peak of one's performance can only be achieved through synchronisation of body and mind";
  }
  if (!keywords) {
    keywords = 'abhyas, school, courses, conference, activities, wellness';
  }

  const baseMeta: MetaDescriptor[] = [
    { title: `${title} | Abhyas` },
    { name: 'description', content: description },
    { name: 'keywords', content: keywords },
    {
      tagName: 'link',
      rel: 'canonical',
      href: new URL(args.location.pathname, 'https://www.abhyas.mu').toString(),
    },
    // { name: 'twitter:site', content: '@vybangalore' },
    { name: 'twitter:url', content: 'https://www.abhyas.mu' },
    { name: 'twitter:title', content: title },
    { name: 'twitter:description', content: description },
    { property: 'og:site_name', content: 'Abhyas' },
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: 'https://www.abhyas.mu/' },
    { property: 'og:title', content: title },
    { property: 'og:description', content: description },
  ];

  const previewImageMeta: MetaDescriptor[] = [
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:image', content: previewImageSrc },
    { name: 'twitter:image:alt', content: previewImageAlt },
    { property: 'og:image', content: previewImageSrc },
    { property: 'og:image:alt', content: previewImageAlt },
    { property: 'og:image:url', content: previewImageSrc },
    { property: 'og:image:secure_url', content: previewImageSrc },
  ];

  return [...baseMeta, ...previewImageMeta, ...(extraMeta ?? [])];
}
