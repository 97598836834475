import { useNavigate } from '@remix-run/react';
import { useCallback } from 'react';
import { Typography } from '~/components/typography';

export interface FeatureCardProps {
  title: string;
  description: string;
  imageUrl: string;
  imageAlt: string;
  cardLink?: string;
}

export function FeatureCard({ title, description, cardLink, imageUrl, imageAlt }: FeatureCardProps) {
  const navigate = useNavigate();
  const handleOnClick = useCallback(() => {
    if (cardLink) {
      navigate(cardLink);
    }
  }, [cardLink, navigate]);

  return (
    <div>
      <div className="h-full w-full cursor-pointer" onClick={handleOnClick}>
        <div className="relative aspect-[1/1.25] h-full w-full">
          <img className="h-full w-full rounded object-cover shadow-md" src={imageUrl} alt={imageAlt} />
        </div>
        <div className="relative -top-12 z-10 mx-4 bg-background px-4 py-2 text-center shadow-lg lg:-top-8">
          <Typography variant="h4" className="font-semibold text-primary">
            {title}
          </Typography>
          <Typography>{description}</Typography>
        </div>
      </div>
    </div>
  );
}
