import type { LinksFunction } from '@remix-run/cloudflare';
import { Links, Meta, Outlet, Scripts, ScrollRestoration, isRouteErrorResponse, useRouteError } from '@remix-run/react';
import fontCss from '~/global-styles/fonts.css?url';
import tailwindStyles from '~/global-styles/tailwind.css?url';
import { Layout as RootLayout } from '~/layouts/root-layout';
import { UnhandledError } from './components/page-section/unhandled-error-page';

export const links: LinksFunction = () => [
  { rel: 'stylesheet', href: tailwindStyles },
  { rel: 'stylesheet', href: fontCss },
];

export function Layout({ children }: { children: React.ReactNode }) {
  const shouldRenderGA = import.meta.env.PROD;
  const gtagId = 'G-F6B5161FWG';
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        {shouldRenderGA && <script async src={`https://www.googletagmanager.com/gtag/js?id=${gtagId}`} />}
        {shouldRenderGA && (
          <script
            id="gtag-init"
            dangerouslySetInnerHTML={{
              __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${gtagId}');
        `,
            }}
          />
        )}
        <Links />
      </head>
      <body>
        <RootLayout>{children}</RootLayout>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export function ErrorBoundary() {
  const error = useRouteError();
  console.error(error);

  if (isRouteErrorResponse(error)) {
    return (
      <UnhandledError title={error.statusText} statusCode={error.status} description={JSON.stringify(error.data)} />
    );
  } else if (error instanceof Error) {
    return <UnhandledError title="Something unexpected happened" statusCode={500} description={error.message} />;
  } else {
    return (
      <UnhandledError title="Something unexpected happened" statusCode={500} description={JSON.stringify(error)} />
    );
  }
}

export default function App() {
  return <Outlet />;
}
